import React, {useRef} from "react";
import {createStyles, makeStyles, useTheme} from "@mui/styles";

import {useTranslation} from "react-i18next";

import Typography from "@mui/material/Typography";
import {OnboardingChildRefInterface, OnboardingChildProps} from "./onboarding";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },
        formControl: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(1),
            minWidth: 200,
        },
        group: {
            margin: theme.spacing(1, 0),
            marginLeft: theme.spacing(4),
        },
        formControlLabel: {
            fontSize: "1rem",
        },
        formControlLabelRoot: {
            marginTop: theme.spacing(1),
            marginLeft: 0,
        },
        warningBox: {
            border: "1px solid gray",
            display: "inline-block",
        },
    })
);

export const AlmostThere = React.forwardRef<OnboardingChildRefInterface, OnboardingChildProps>(
    ({showParentProgress, advanceParentToNextStep}, ref) => {


        const theme = useTheme()
        const classes = useStyles(theme);

        const {t} = useTranslation();


        React.useImperativeHandle(ref, () => ({
            //called by parent
            submitStep: () => {
                advanceParentToNextStep()
            },
        }));

        return (
            <div
                className={classes.root}
            >
                <Typography align="center" variant="h5" gutterBottom>
                    {t("respect-marine-life")}
                </Typography>

                <Typography variant="body2">{t("profile-share-instructions")}</Typography>

                <img
                    src="/images/onboarding/onboarding_cool_network.svg"
                    style={{
                        display: "block",
                        width: 165,
                        height: 122,
                        margin: "24px auto",
                    }}
                />

                <Typography variant="body2">{t("if-cool-get-access")}</Typography>
            </div>
        );
    });
