import {combineReducers} from '@reduxjs/toolkit';
import {reducer as userSessionReducer} from '../slices/user-session';
import {reducer as rateUserReducer} from '../slices/rate-user';
import {reducer as tripsReducer} from '../slices/trips';
import {reducer as onboardingReducer} from '../slices/onboarding-slice';
import {firestoreApi} from "../slices/firestore-slice";

export const rootReducer = combineReducers({
    trips: tripsReducer,
    userSession: userSessionReducer,
    rateUser: rateUserReducer,
    onboarding: onboardingReducer,
    [firestoreApi.reducerPath]: firestoreApi.reducer,
});
