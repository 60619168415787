import {useDispatch as useReduxDispatch, useSelector as useReduxSelector} from 'react-redux';
import type {TypedUseSelectorHook} from 'react-redux';
import type {ThunkAction} from 'redux-thunk';
import {configureStore} from '@reduxjs/toolkit';
import type {Action} from '@reduxjs/toolkit';
import {rootReducer} from './root-reducer';
import {setupListeners} from "@reduxjs/toolkit/query";
import {firestoreApi} from "../slices/firestore-slice";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';



const persistConfig = {
    key: 'root',
    storage,
    // blacklist: ['onboarding'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({serializableCheck: false}).concat(firestoreApi.middleware),
    devTools: process.env.NEXT_PUBLIC_REACT_APP_ENABLE_REDUX_DEV_TOOLS === "true"
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export const persistor = persistStore(store)


setupListeners(store.dispatch)

