import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {AppThunk} from '../store';

export interface RateUserProfile {
    firstName: string,
    photoUrl: string,
    username: string,
    uid: string
}

export interface RateUserFormData {
    lastDiveTogetherYear: string,
    lastDiveTogetherMonth: string,
    attitude: string,
    skills: string,
    deserves: string
}

interface RateUserState {
    rateUserProfile: RateUserProfile;
    rateUserFormData: RateUserFormData;
}

const initialState: RateUserState = {
    rateUserProfile: {
        firstName: "",
        photoUrl: "",
        username: "",
        uid: "",
    },
    rateUserFormData: {
        lastDiveTogetherMonth: "",
        lastDiveTogetherYear: "",
        skills: "",
        attitude: "",
        deserves: ""
    }
};

const slice = createSlice({
        name: 'rateUser',
        initialState,
        reducers: {
            setRateUserProfile(state: RateUserState, action: PayloadAction<RateUserProfile>): void {
                state.rateUserProfile = Object.assign({}, state.rateUserProfile, action.payload)
            },
            setRateUserFormData(state: RateUserState, action: PayloadAction<RateUserFormData>): void {
                state.rateUserFormData = Object.assign({}, state.rateUserFormData, action.payload)
            },
        }
    })
;

export const {reducer} = slice;

export const setRateUserProfile = (rateUserProfile: RateUserProfile): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.setRateUserProfile(rateUserProfile));
};

export const setFamiliarityData = (lastDiveTogetherMonth: string, lastDiveTogetherYear: string): AppThunk => async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setRateUserFormData(Object.assign({}, getState().rateUser.rateUserFormData,
        {lastDiveTogetherMonth, lastDiveTogetherYear}
        )));
};

export const setAttitudeData = (attitude: string): AppThunk => async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setRateUserFormData(Object.assign({}, getState().rateUser.rateUserFormData,
        {attitude}
    )));
};

export const setSkillsData = (skills: string): AppThunk => async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setRateUserFormData(Object.assign({}, getState().rateUser.rateUserFormData,
        {skills}
    )));
};

export const setDeservesData = (deserves: string): AppThunk => async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setRateUserFormData(Object.assign({}, getState().rateUser.rateUserFormData,
        {deserves}
    )));
};
