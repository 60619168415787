// @ts-nocheck
export const getOrientation = (file, callback: (number) => void) => {
  const reader = new FileReader();

  reader.onload = function(event) {
    const view = new DataView(event.target.result);

    if (view.getUint16(0, false) != 0xffd8) return callback(-2);

    let length = view.byteLength;
    let offSet = 2;

    while (offSet < length) {
      const marker = view.getUint16(offSet, false);
      offSet += 2;

      if (marker == 0xffe1) {
        if (view.getUint32((offSet += 2), false) != 0x45786966) {
          return callback(-1);
        }
        const little = view.getUint16((offSet += 6), false) == 0x4949;
        offSet += view.getUint32(offSet + 4, little);
        const tags = view.getUint16(offSet, little);
        offSet += 2;

        for (let i = 0; i < tags; i++)
          if (view.getUint16(offSet + i * 12, little) == 0x0112)
            return callback(view.getUint16(offSet + i * 12 + 8, little));
      } else if ((marker & 0xff00) != 0xff00) break;
      else offSet += view.getUint16(offSet, false);
    }
    return callback(-1);
  };

  reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
};

export const resetOrientation = (srcBase64:string | ArrayBuffer, srcOrientation:number, callback: (string) => void) => {
  const img = new Image();

  img.onload = function() {
    let width = img.width,
      height = img.height,
      canvas = document.createElement("canvas"),
      ctx = canvas.getContext("2d");

    // set proper canvas dimensions before transform & export
    if (4 < srcOrientation && srcOrientation < 9) {
      // noinspection JSSuspiciousNameCombination
      canvas.width = height;
      // noinspection JSSuspiciousNameCombination
      canvas.height = width;
    } else {
      canvas.width = width;
      canvas.height = height;
    }

    // transform context before drawing image
    switch (srcOrientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, width, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, width, height);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, height);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, height, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, height, width);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, width);
        break;
      default:
        break;
    }

    // draw image
    ctx.drawImage(img, 0, 0);

    // export base64
    callback(canvas.toDataURL());
  };

  img.src = srcBase64;
};

export const isTouchDevice = () => {
  return "ontouchstart" in document.documentElement;
};

export const offset = el => {
  const rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

export const localize = (value, languages) => {
  if (Array.isArray(value)) {
    return value.map(v => localize(v, languages));
  } else if (typeof value == "object") {
    if (/^locale[A-Z]/.test(value._type)) {
      const language = languages.find(lang => value[lang]);
      return value[language];
    }

    return Object.keys(value).reduce((result, key) => {
      result[key] = localize(value[key], languages);
      return result;
    }, {});
  }
  return value;
};


export const slugify = (...args: (string | number)[]): string => {
  const value = args.join(' ')

  return value
      .normalize('NFD') // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, '-') // separator
}
