import React, {useEffect, useRef, useState} from "react";
import {createStyles, makeStyles, useTheme} from "@mui/styles";
import {useTranslation} from "react-i18next";

import InputAdornment from "@mui/material/InputAdornment";
import LocationOn from "@mui/icons-material/LocationOn";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {getName, getNames, registerLocale} from "i18n-iso-countries";
import englishCountryLocaleData from "i18n-iso-countries/langs/en.json";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import "firebase/storage";
import {getOrientation, resetOrientation} from "../../lib/util";
import {Autocomplete} from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {UserAvatar} from "../user-avatar";
import {useFormik} from "formik";
import diverCertifications from '../../diver-certifications.json'
import {PhotoCropAndUploadDialog} from "../photo-crop-and-upload-dialog";
import {updateProfilePhoto, updateProfileFormData} from "../../slices/user-session";
import {useAuth} from "../../hooks/use-auth";
import {useDispatch, useSelector} from "../../store";
import {OnboardingChildProps, OnboardingChildRefInterface} from "./onboarding";

const countryToFlag = (isoCode: string) => {
    return typeof String.fromCodePoint !== "undefined"
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) =>
                String.fromCodePoint(char.charCodeAt(0) + 127397)
            )
        : isoCode;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(1),
            minWidth: 240,
        },
        certificationLabel: {
            marginLeft: theme.spacing(3),
        },
        group: {
            margin: theme.spacing(1, 0),
            marginLeft: theme.spacing(4),
        },
        formControlLabel: {
            fontSize: "1rem",
        },
        formControlLabelRoot: {
            marginTop: theme.spacing(1),
            marginLeft: 0,
        },
        avatarContainer: {
            position: "relative",
            cursor: "pointer",
            marginLeft: theme.spacing(3),
            left: 8,
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        autoCompletePopup: {
            zIndex: 2147483647,
        },
    })
);


export const ProfileForm = React.forwardRef<OnboardingChildRefInterface, OnboardingChildProps>(({
                                                                                        showParentProgress,
                                                                                        advanceParentToNextStep
                                                                                    }, ref) => {

    useEffect(() => {
        registerLocale(englishCountryLocaleData);
    }, []);


    const {user} = useAuth()

    const uid = user?.uid

    const dispatch = useDispatch();

    const theme = useTheme()

    const classes = useStyles(theme);

    const {t} = useTranslation();


    const [src, setSrc] = useState(null);

    const {currentProfile} = useSelector((state) => state.userSession);

    const {
        homeCountryCode,
        currentLocation,
        certBody,
        certLevel,
        numberOfDives
    } = currentProfile

    // const [formData, setFormData] = useState({
    //     homeCountryCode: "",
    //     currentLocation: "",
    //     certBody: "",
    //     certLevel: "",
    //     numberOfDives: "",
    // });
    //
    // useEffect(() => {
    //     if (isLoaded(profile) && !isEmpty(profile)) {
    //         const formDataFromServer = [
    //             "homeCountryCode",
    //             "currentLocation",
    //             "certBody",
    //             "certLevel",
    //             "numberOfDives",
    //         ].reduce((obj, item, index, array) => {
    //             if (!formData[item]) obj[item] = profile[item];
    //             return obj;
    //         }, {});
    //
    //         formDataFromServer["agreedToTerms"] = !!profile.agreedToTerms;
    //
    //         setFormData({...formData, ...formDataFromServer});
    //     }
    // }, [profile]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            homeCountryCode,
            currentLocation,
            certBody,
            certLevel,
            numberOfDives
        },
        validate: (values) => {
            const errors: any = {};
            if (!values.currentLocation) {
                errors.currentLocation = true;
            }

            if (!values.certBody) {
                errors.certBody = true;
            }

            if (!values.certLevel) {
                errors.certLevel = true;
            }

            if (!values.homeCountryCode) {
                errors.homeCountryCode = true;
            }

            if (!values.numberOfDives) {
                errors.numberOfDives = true;
            }

            return errors;
        },

        onSubmit: async (values, {setSubmitting, resetForm}) => {

            showParentProgress(true)




            try {

                const {
                    homeCountryCode,
                    currentLocation,
                    certBody,
                    certLevel,
                    numberOfDives
                } = values

                dispatch(updateProfileFormData({
                    homeCountryCode,
                    currentLocation,
                    certBody,
                    certLevel,
                    numberOfDives
                }))

                advanceParentToNextStep()
            } catch (error) {
                showParentProgress(false)
                console.error("Error setting document with facebook data: ", error);
                // gtag("event", "exception", {
                //   description: "SettingsForm error updating document ",
                //   fatal: true,
                // });
            }

        },
    });

    useEffect(() => {
        if (!formik.isSubmitting) {
            showParentProgress(false)
        }
    }, [formik.isSubmitting]);

    const photoInputRef = useRef<HTMLInputElement>(null);

    React.useImperativeHandle(ref, () => ({
        //called by parent
        submitStep: () => {
            formik.handleSubmit();
        },
    }));

    const convertISOtoOption = (isoCode: string) => {
        if (!isoCode) return null
        return {code: isoCode, label: getName(isoCode, "en") || ""};
    };

    const countriesAsObject = getNames("en");

    const countries = Object.keys(countriesAsObject).map((key, idx) => {
        return {code: key, label: countriesAsObject[key]};
    });

    const onSelectFile = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.addEventListener(
                "load",
                () =>
                    getOrientation(file, (orientaitonCode) => {
                        resetOrientation(
                            reader.result || "",
                            orientaitonCode,
                            (rotatedDataUrl) => {
                                setSrc(rotatedDataUrl);
                            }
                        );
                    }),
                false
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handlePhotoCropAndUploadFinished = (url: string | null) => {
        if (!!url) {
            if (photoInputRef.current) photoInputRef.current.value = ''
            dispatch(updateProfilePhoto(url));
        }
        setSrc(null)
    };

    return (
        <Box sx={{pt:3}}>
            <form>
                <Grid container alignItems={"center"}>
                    <Grid item>
                        <div className={classes.avatarContainer}>
                            <UserAvatar
                                photoUrl={currentProfile.photoUrl}
                                homeCountryCode={formik.values.homeCountryCode}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <input
                            ref={photoInputRef}
                            accept="image/*"
                            id="raised-button-file-profile-form"
                            type="file"
                            style={{display: "none"}}
                            onChange={onSelectFile}
                        />
                        <label htmlFor="raised-button-file-profile-form">
                            <Box ml={1}>
                                <Button
                                    variant="text"
                                    color={"primary"}
                                    component="span"
                                    size={"medium"}
                                >
                                    <PhotoCamera className={classes.leftIcon}/>
                                    {t("upload")}
                                </Button>
                            </Box>
                        </label>
                    </Grid>
                </Grid>

                <Autocomplete
                    // name="homeCountryCode"
                    className={classes.formControl}
                    value={convertISOtoOption(formik.values.homeCountryCode)}
                    // getOptionSelected={(option, value) => {
                    //     return option.code === value.code
                    // }}
                    onChange={(e, newValue) => {
                        formik.handleChange("homeCountryCode")(
                            newValue ? newValue.code : ""
                        );
                    }}
                    options={countries}
                    classes={{
                        popper: classes.autoCompletePopup,
                    }}
                    getOptionLabel={(option) =>
                        Array.isArray(option.label) ? option.label[0] : option.label
                    }
                    renderOption={(props, option) => (
                        <Box component={"li"} {...props}>
                            <span>{countryToFlag(option.code)}</span>&nbsp;{option.label} ({option.code})
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            error={formik.submitCount > 0 && !!formik.errors.homeCountryCode}
                            {...params}
                            label={t("nationality")}
                            variant="standard"
                            style={{minWidth: 240}}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: "disabled", // disable autocomplete and autofill
                            }}
                        />
                    )}
                />

                <TextField
                    error={formik.submitCount > 0 && !!formik.errors.currentLocation}
                    className={classes.formControl}
                    label={t("location")}
                    name="currentLocation"
                    id="currentLocation"
                    onChange={formik.handleChange}
                    value={formik.values.currentLocation}
                    placeholder={t("example-location")}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LocationOn/>
                            </InputAdornment>
                        ),
                    }}
                />

                <br/>

                <InputLabel
                    shrink
                    htmlFor="certLevel-helper"
                    className={classes.certificationLabel}
                    style={{marginTop: 6}}
                    error={
                        formik.submitCount > 0 &&
                        (!!formik.errors.certLevel || !!formik.errors.certBody)
                    }
                >
                    {t("highest-certification")}
                </InputLabel>
                <Grid container className={classes.certificationLabel} wrap={"nowrap"}>
                    <Grid item>
                        <FormControl
                            style={{marginRight: 6}}
                            error={formik.submitCount > 0 && !!formik.errors.certLevel}
                        >
                            <Select
                                value={formik.values.certBody}
                                onChange={formik.handleChange}
                                inputProps={{
                                    name: "certBody",
                                    id: "certBody-helper",
                                }}
                                style={{minWidth: 60}}
                                MenuProps={{
                                    style: {zIndex: 2147483647},
                                }}
                            >
                                {Object.keys(diverCertifications).map((keyName) => {
                                    return (
                                        <MenuItem key={keyName} value={keyName}>
                                            {keyName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl
                            error={formik.submitCount > 0 && !!formik.errors.certLevel}
                        >
                            <Select
                                value={formik.values.certLevel}
                                onChange={formik.handleChange}
                                inputProps={{
                                    name: "certLevel",
                                    id: "certLevel-helper",
                                }}
                                style={{minWidth: 100}}
                                MenuProps={{
                                    style: {zIndex: 2147483647},
                                }}
                                renderValue={(value) =>
                                    value
                                        .replace(/Instructor$/, "Instr.")
                                        .replace(/Advanced/, "Adv.")
                                }
                            >
                                {!!formik.values.certBody &&
                                    diverCertifications[formik.values.certBody as keyof typeof diverCertifications].map((keyName, idx) => (
                                        <MenuItem key={keyName} value={keyName}>
                                            {keyName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <FormControl
                    className={classes.formControl}
                    error={formik.submitCount > 0 && !!formik.errors.numberOfDives}
                >
                    <InputLabel>
                        {t("number-of-dives")}
                    </InputLabel>
                    <Select
                        label={t("number-of-dives")}
                        value={formik.values.numberOfDives}
                        onChange={formik.handleChange}
                        inputProps={{
                            name: "numberOfDives",
                            id: "num-dives-helper",
                        }}
                        MenuProps={{
                            style: {zIndex: 2147483647},
                        }}
                    >
                        <MenuItem value="<50">
                            {t("less-than-dives", {dives: 50})}
                        </MenuItem>
                        <MenuItem value="50+">
                            {t("more-than-dives", {dives: 50})}
                        </MenuItem>
                        <MenuItem value="100+">
                            {t("more-than-dives", {dives: 100})}
                        </MenuItem>
                        <MenuItem value="300+">
                            {t("more-than-dives", {dives: 300})}
                        </MenuItem>
                        <MenuItem value="1000+">
                            {t("more-than-dives", {dives: 1000})}
                        </MenuItem>
                    </Select>
                </FormControl>
            </form>

            <PhotoCropAndUploadDialog
                src={src}
                onFinished={handlePhotoCropAndUploadFinished}
                storageDestination={`user/${uid}/profile/profile_photo_${uid}.jpg`}
                aspect={1}
                circularCrop={true}
            />
        </Box>
    );
});
