import React, {useState} from "react";
import {createStyles, makeStyles, useTheme} from "@mui/styles";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import * as Yup from "yup";

import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import {TruthyCheckbox} from "../truthy-checkbox";
import Grid from "@mui/material/Grid";
import {useFormik} from "formik";
import {OnboardingChildProps, OnboardingChildRefInterface} from "./onboarding";
import {getDocRef} from "../../lib/firebase";
import {useAuth} from "../../hooks/use-auth";
import {setDoc, serverTimestamp} from "firebase/firestore";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },
        group: {
            margin: theme.spacing(1, 0),
            marginLeft: theme.spacing(4),
        },
        formControlLabel: {
            fontSize: "1rem",
        },
        formControlLabelRoot: {
            marginTop: theme.spacing(1),
            marginLeft: 0,
        },
    })
);

const BasicFormSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    lastName: Yup.string()
        .min(1, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    agreedToTerms: Yup.bool().oneOf(
        [true],
        "Accept Terms & Conditions is required"
    ),
});

export const BasicForm = React.forwardRef<OnboardingChildRefInterface, OnboardingChildProps>(({
                                                                                                  showParentProgress,
                                                                                                  advanceParentToNextStep
                                                                                              }, ref) => {

    const auth = useAuth()
    const theme = useTheme()
    const {t} = useTranslation()

    const classes = useStyles(theme);





    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        marketingOptIn: true,
        agreedToTerms: false,
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: BasicFormSchema,
        initialValues: formData,
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            console.log("submitting form");
            showParentProgress(true);

            try {

                await setDoc(
                    getDocRef("users", auth.user?.uid),
                    {...values, agreedToTerms: serverTimestamp()},
                    {merge: true}
                )


                advanceParentToNextStep();
            } catch (e) {
                showParentProgress(false);
                console.error("Error setting document with facebook data: ", e.message);
                // gtag("event", "exception", {
                //   description: "SettingsForm error updating document ",
                //   fatal: true,
                // });
            }
        },
    });

    React.useImperativeHandle(ref, () => ({
        //called by parent
        submitStep: () => {
            console.log("submitStep(), ", formData);
            formik.handleSubmit();
        },
    }));


    return (
        <form className={classes.root}>
            <Grid container direction={"column"} spacing={2}>
                <Grid item>
                    <TextField
                        label={t("first-name")}
                        name="firstName"
                        required
                        error={Boolean(formik.errors.firstName) && Boolean(formik.touched.firstName)}
                        autoComplete="given-name"
                        onChange={formik.handleChange}
                        fullWidth
                        value={formik.values.firstName}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label={t("last-name")}
                        name="lastName"
                        required
                        autoComplete="family-name"
                        onChange={formik.handleChange}
                        fullWidth
                        value={formik.values.lastName}
                        error={Boolean(formik.errors.lastName) && Boolean(formik.touched.lastName)}
                    />
                </Grid>

                <Grid item>
                    <TextField
                        label="Email"
                        name="email"
                        required
                        onChange={formik.handleChange}
                        fullWidth
                        type="email"
                        value={formik.values.email}
                        error={Boolean(formik.errors.email) && Boolean(formik.touched.email)}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        classes={{
                            root: classes.formControlLabelRoot,
                            label: classes.formControlLabel,
                        }}
                        control={
                            <Checkbox
                                checked={formik.values.marketingOptIn}
                                onChange={formik.handleChange}
                                color="primary"
                                id="marketingOptIn"
                                name="marketingOptIn"
                                style={{margin: 12, marginRight: 8}}
                            />
                        }
                        label={t("i-agree-to-marketing-email")}
                    />
                </Grid>
                <Grid item>
                    <TruthyCheckbox
                        checked={formik.values.agreedToTerms}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.agreedToTerms)}
                        id="agreedToTerms"
                        name="agreedToTerms"
                        style={{margin: 12}}
                        labelText={t("i-agree-terms")}
                    />
                </Grid>
            </Grid>
        </form>
    );
});
