import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {AppThunk} from '../store';


interface OnboardingState {
    showOnboarding: boolean
    activeStep: number
}

const initialState: OnboardingState = {
    showOnboarding: false,
    activeStep: 0
};

const slice = createSlice({
        name: 'onboarding',
        initialState,
        reducers: {
            setShowOnboarding(state: OnboardingState, action: PayloadAction<boolean>): void {
                state.showOnboarding = action.payload
            },
            setActiveStep(state: OnboardingState, action: PayloadAction<number>): void {
                state.activeStep = action.payload
            },
        }
    })
;

export const {reducer} = slice;


export const setShowOnboarding = (showOnboarding: boolean): AppThunk => async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setShowOnboarding(showOnboarding))
};

export const setActiveStep = (activeStep: number): AppThunk => async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setActiveStep(activeStep))
};
