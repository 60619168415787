import React, {useEffect, useRef, FC} from "react";
import {createStyles, makeStyles, useTheme} from "@mui/styles";
import FacebookIcon from "mdi-material-ui/Facebook";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            color: "#fff",
            backgroundColor: "#4468b0",
            "&:hover": {
                backgroundColor: "#003e80"
            },
            borderRadius: 4,
            fontFamily: "Helvetica, Arial, sans-serif",
            textTransform: "none",
            whiteSpace: "nowrap",
            fontSize: 16,
            letterSpacing: ".25px",
            overflow: "hidden",
            fontWeight: 400,
            height: 40,
            padding: 0,
            paddingLeft: 5,
            paddingRight: 4,
            "&:disabled": {
                backgroundColor: "#e6e6e6"
            }
        },
        label: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        }
    })
);

export const FacebookButton: FC<any> = (props: any) => {

    const theme = useTheme()

    const {t} = useTranslation();

    const classes = useStyles(theme);

    const {onClick, style, ...buttonProps} = props;

    const [isLoading, setLoading] = React.useState(false);

    const timerRef = useRef<NodeJS.Timeout>();

    const handleClick = (e: any) => {
        setLoading(true);
        onClick(e);
        timerRef.current = setTimeout(() => {
            setLoading(false);
        }, 30 * 1000);
    };

    useEffect(() => {
        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, []);

    return (
        <LoadingButton
            variant="text"
            loading={isLoading}
            className={classes.root}
            onClick={handleClick}
            style={style}
            {...buttonProps}
            startIcon={<FacebookIcon style={{fontSize: 30, marginLeft: 8}}/>}
        >
            <div className={classes.label}>{t("continue-with-facebook")}</div>
        </LoadingButton>
    );
}
