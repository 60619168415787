import {makeStyles, createStyles, useTheme} from '@mui/styles';

import {Avatar} from '@mui/material';
import classNames from 'clsx';
import {Theme} from "@mui/system";
import {FC} from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatarHolder: {
            display: 'inline-block',
            position: 'relative',
        },
        avatar: {
            position: 'relative',
            marginTop: 10,
            marginLeft: -14,
            marginRight: 2,
            marginBottom: 2,
            width: 60,
            height: 60,
            border: '3px solid white',
        },
        linkableEffect: {
            cursor: 'pointer',
            '&:hover': {
                width: 64,
                height: 64,
                marginTop: 6,
                marginLeft: -16,
                marginRight: 0,
            },
        },
        avatarFlag: {
            position: 'absolute',
            width: 18,
            height: 18,
            left: -6,
            bottom: 0
        }
    }));


interface UserAvatarProps {
    photoUrl?: string;
    homeCountryCode?: string;
    username?: string;
    alternateBackgroundColor?: string;
}

export const UserAvatar:FC<UserAvatarProps> = ({photoUrl, username, homeCountryCode, alternateBackgroundColor}) => {

    const theme = useTheme()

    const classes = useStyles(theme);

    const avatar = <Avatar
        className={classNames([classes.avatar, {[classes.linkableEffect]: !!username}])}
        src={photoUrl}
        alt={username || "profile image"}
        style={!!alternateBackgroundColor ? {borderColor: alternateBackgroundColor} : {}}
    />;

    if (username) {
        return <a className={classes.avatarHolder} href={`/u/${username}`}>
            {avatar}
            {homeCountryCode &&
                <img className={classes.avatarFlag} src={`/images/flags/${homeCountryCode}.png`} alt={homeCountryCode}/>}
        </a>
    } else {
        return <div className={classes.avatarHolder}>
            {avatar}
            {homeCountryCode &&
                <img className={classes.avatarFlag} src={`/images/flags/${homeCountryCode}.png`} alt={homeCountryCode}/>}
        </div>
    }


}

