import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, useTheme } from "@mui/styles";

import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import {FacebookButton} from "./facebook-button";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import Fade from "@mui/material/Fade";
import {useDispatch, useSelector} from "../store";
import {setShowAuthWall} from "../slices/user-session";
import {useAuth} from "../hooks/use-auth";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    heart: {
      width: 60,
      display: "block",
      margin: "0 auto",
    },
    title: {
      textAlign: "center",
    },
    closeButton: {
      position: "absolute",
      right: 8,
      top: 8,
      color: "gray",
      cursor: "pointer",
    },
    modal: {
      position: "absolute",
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,

      backgroundColor: "#fff",
      boxShadow: "0 5px 15px rgba(0, 0, 0, .5)",

      textAlign: "center",
      minWidth: 270,
      maxWidth: 900,
      [theme.breakpoints.down("xs")]: {
        maxWidth: 700,
      },
    },
    desktopLeft: {
      width: 300,
      backgroundColor: "#0c5ea7",
      backgroundImage: 'url("/images/authwall-desktop-left.webp")',
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    signUpContent: {
      padding: 8 * 2,
      border: "1px solid #e5e5e5",

      [theme.breakpoints.up("sm")]: {
        maxWidth: 370,
        width: 370,
        padding: 8 * 4,
      },
    },
    logo: {
      [theme.breakpoints.down("xs")]: {
        width: 240,
      },
      width: 270,
      marginBottom: 16,
    },
    signupItem: {
      marginBottom: 8,
      color: "#4f4f4f",
      fontWeight: 500,
      fontSize: 18,
      position: "relative",
      "&::before": {
        fontSize: 14,
        content: "'\\25BA \\0020'",
        position: "absolute",
        top: 4,
        left: -18,
      },
    },
    list: {
      marginLeft: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(6),
      },
      listStyle: "none",
      position: "relative",
      marginTop: 4,
      marginBottom: theme.spacing(4),
      fontSize: "1rem",
      textAlign: "left",
    },
  })
);

export const AuthWall = () => {

  const theme = useTheme()

  const classes = useStyles(theme)

  const {showAuthWall} = useSelector((state) => state.userSession);

  const [submitting, setSubmitting] = useState(false);

  const [hasEventListener, setHasEventListener] = useState(false);

  const dispatch = useDispatch();

  const {signInWithFacebook } = useAuth();


  // const history = useHistory();


  const { t } = useTranslation();

  // const location = useLocation();

  const handleBackButton = (event: any) => {
    event.preventDefault();
    dispatch(setShowAuthWall(false));
  };

  useEffect(() => {
    if (showAuthWall) {
      //window.alert("add event listener")
      //history.push("#authwall");
      window.addEventListener("popstate", handleBackButton, true);
      setHasEventListener(true);
      setSubmitting(false);
    } else {
      if (hasEventListener) {
        window.removeEventListener("popstate", handleBackButton, true);
        setHasEventListener(false);
        setSubmitting(false);
      }
      //if (location.hash === "#authwall") history.goBack();
      //window.alert("remove event listener")
    }
  }, [showAuthWall]);

  const loginClicked = async (event: any) => {
    setSubmitting(true);

    try {
      await signInWithFacebook();
      dispatch(setShowAuthWall(false));
    } catch (e) {
      setSubmitting(false);
    }

  };

  return (
    <Modal
      aria-label={"Authentication Screen"}
      open={showAuthWall}
      onClose={(e, reason) => {
        dispatch(setShowAuthWall(false));
      }}
      disableAutoFocus={true}
      className={classes.root}
      // couldn't figure out how to raise the zIndex here, so ended up lowering the one of the new review dialog
      // style={{ zIndex: 1305 }}
      // classes={{ root: { zIndex: 1305 } }}
    >
      <Fade in={showAuthWall} timeout={400}>
        <div className={classes.modal}>
          <Grid container direction="row" wrap="nowrap">
            <Hidden smDown>
              <Grid item className={classes.desktopLeft}></Grid>
            </Hidden>
            <Grid item className={classes.signUpContent}>
              <img
                src="/images/seacrush_logo_horizontal_black.svg"
                width="480"
                height="69"
                alt="SeaCrush Logo Horizontal"
                className={classes.logo}
              />

              <ul className={classes.list}>
                <li className={classes.signupItem}>{t("sign-up-bullet-1")}</li>
                <li className={classes.signupItem}>{t("sign-up-bullet-2")}</li>
                <li
                  className={classes.signupItem}
                  style={{ color: "#000", fontWeight: 700 }}
                >
                  {t("sign-up-bullet-3")}
                </li>
              </ul>

              <FacebookButton disabled={submitting} onClick={loginClicked} />

              <Typography
                component={"div"}
                style={{ marginTop: 4 }}
                variant="caption"
              >
                <br />
                <span
                  dangerouslySetInnerHTML={{ __html: t("by-signing-in") }}
                />
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
}
