
import red from "@mui/material/colors/red"
import Checkbox, {CheckboxProps} from "@mui/material/Checkbox"
import Typography from "@mui/material/Typography"
import classNames from "clsx"
import { createStyles, makeStyles, useTheme } from "@mui/styles"
import {FC} from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      fontSize: "1rem",
      marginRight: 12,
    },
    error: {
      color: red["500"],
    },
    labelRoot: {
      display: "inline-flex",
      alignItems: "center",
      cursor: "pointer",
      // For correct alignment with the text.
      verticalAlign: "middle",
      // Remove grey highlight
      WebkitTapHighlightColor: "transparent",
      marginLeft: 0,
    },
  })
);

interface TruthyCheckboxProps extends CheckboxProps{
    labelText: string,
    error: boolean
}


export const TruthyCheckbox:FC<TruthyCheckboxProps> = ({ labelText, checked, name, onChange, style, error, inputRef }) => {

    const theme = useTheme()

  const classes = useStyles(theme);

  return (
    <label className={classes.labelRoot}>
      <Checkbox
        onChange={onChange}
        checked={checked}
        name={name}
        value={name}
        color="primary"
        style={style}
        inputRef={inputRef}
      />
      <Typography
        component="span"
        className={classNames(classes.text, { [classes.error]: error })}
      >
        <span dangerouslySetInnerHTML={{ __html: labelText }} />
      </Typography>
    </label>
  );
}

