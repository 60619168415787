// import {initFirebaseAdminApp} from "seacrush-core/dist/service/external/firebase-admin-initializer"
import type {FC} from 'react';
import type {NextPage} from 'next';
import type {AppProps} from 'next/app';
import Head from 'next/head';
import Router from 'next-translate-routes/router';
import {Toaster} from 'react-hot-toast';
import {Provider as ReduxProvider} from 'react-redux';
import nProgress from 'nprogress';
import {CacheProvider} from '@emotion/react';
import type {EmotionCache} from '@emotion/cache';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {SettingsButton} from '../src/components/settings-button';
import {SplashScreen} from '../src/components/splash-screen';
import {SettingsConsumer, SettingsProvider} from '../src/contexts/settings-context';
import {AuthConsumer, AuthProvider} from '../src/contexts/firebase-auth-context';
import { gtmConfig } from '../src/config';
import { gtm } from '../src/lib/gtm';
import {store, persistor} from '../src/store';
import {createTheme} from '../src/theme';
import {createEmotionCache} from '../src/utils/create-emotion-cache';
import {withTranslateRoutes} from "next-translate-routes";
import {appWithTranslation} from 'next-i18next'
import "../src/global.scss"
import {i18nConfig} from "../src/i18nextConfig";
import {QueryParamProvider} from "use-query-params";
import {NextAdapter} from "next-query-params";
import {Onboarding} from "../src/components/onboarding/onboarding";
import {AuthWall} from "../src/components/auth-wall";
import {PersistGate} from "redux-persist/integration/react";
import {useEffect} from "react";

//
// if (typeof window != "undefined") {
//     initFirebaseAdminApp()
// }

type EnhancedAppProps = AppProps & {
    Component: NextPage;
    emotionCache: EmotionCache;
}

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const clientSideEmotionCache = createEmotionCache();

const App: FC<EnhancedAppProps> = (props) => {
    const {Component, emotionCache = clientSideEmotionCache, pageProps} = props;

    const getLayout = Component.getLayout ?? ((page) => page);

    useEffect(() => {
        gtm.initialize(gtmConfig);
    }, []);

    return (
        <CacheProvider value={emotionCache}>
            <Head>
                <title>
                    SeaCrush
                </title>
                <meta
                    name="viewport"
                    content="initial-scale=1, width=device-width"
                />
                <link rel="icon"
                      type="image/svg+xml"
                      href={`/favicon/favicon_${process.env.NEXT_PUBLIC_ENV || "prod"}_light.svg`}
                      media="(prefers-color-scheme:no-preference)"
                />
                <link rel="icon"
                      type="image/svg+xml"
                      href={`/favicon/favicon_${process.env.NEXT_PUBLIC_ENV || "prod"}_dark.svg`}
                      media="(prefers-color-scheme:dark)"
                />
                <link rel="icon"
                      type="image/svg+xml"
                      href={`/favicon/favicon_${process.env.NEXT_PUBLIC_ENV || "prod"}_light.svg`}
                      media="(prefers-color-scheme:light)"
                />
            </Head>

            <ReduxProvider store={store}>
                <PersistGate loading={<SplashScreen/>} persistor={persistor}>
                <QueryParamProvider adapter={NextAdapter}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <AuthProvider>
                        <SettingsProvider>
                            <SettingsConsumer>
                                {({settings}) => {
                                    let theme = createTheme({
                                        direction: settings.direction,
                                        responsiveFontSizes: settings.responsiveFontSizes,
                                        mode: settings.theme
                                    });
                                    return (
                                        <ThemeProvider
                                            theme={theme}
                                        >
                                            <CssBaseline/>
                                            <Toaster
                                                toastOptions={{
                                                    // className: '',
                                                    position: "top-center",
                                                    style: {
                                                        border: '1px solid',
                                                        borderColor: theme.palette.divider,
                                                        borderRadius: 0,
                                                        color: theme.palette.primary.main

                                                    },
                                                }}
                                            />
                                            <SettingsButton/>
                                            <AuthConsumer>
                                                {
                                                    (auth) => !auth.isInitialized
                                                        ? <SplashScreen/>
                                                        : getLayout(<Component {...pageProps} />)
                                                }
                                            </AuthConsumer>
                                            <AuthWall/>
                                            <Onboarding/>
                                        </ThemeProvider>
                                    );
                                }}
                            </SettingsConsumer>
                        </SettingsProvider>
                    </AuthProvider>
                </LocalizationProvider>
                </QueryParamProvider>
                </PersistGate>
            </ReduxProvider>
        </CacheProvider>
    );
};

export default withTranslateRoutes(JSON.parse(`{"debug":true,"defaultLocale":"default","locales":["default","en","fr"],"routesTree":{"name":"","paths":{"default":""},"children":[{"name":"_middleware","paths":{"default":"_middleware"}},{"name":"about","paths":{"default":"about","en":"about","fr":"a-propos"}},{"name":"concept","paths":{"default":"concept"}},{"name":"contact","paths":{"default":"contact"}},{"name":"index","paths":{"default":"index"}},{"name":"request-booking","paths":{"default":"request-booking"}},{"name":"settings","paths":{"default":"settings"}},{"name":"admin","paths":{"default":"admin"},"children":[{"name":"country-gen","paths":{"default":"country-gen"}}]},{"name":"articles","paths":{"default":"articles","en":"blog","fr":"articles"},"children":[{"name":"index","paths":{"default":"index"}},{"name":"[slug]","paths":{"default":":slug"}}]},{"name":"authentication","paths":{"default":"authentication"},"children":[{"name":"login","paths":{"default":"login"}},{"name":"password-recovery","paths":{"default":"password-recovery"}},{"name":"password-reset","paths":{"default":"password-reset"}},{"name":"register","paths":{"default":"register"}},{"name":"verify-code","paths":{"default":"verify-code"}}]},{"name":"diving","paths":{"default":"diving","en":"diving","fr":"plongee"},"children":[{"name":"index","paths":{"default":"index"}},{"name":"[country]","paths":{"default":":country"},"children":[{"name":"index","paths":{"default":"index"}},{"name":"[slug]","paths":{"default":":slug"}}]}]},{"name":"docs","paths":{"default":"docs"},"children":[{"name":"[docSlug]","paths":{"default":":docSlug"}}]},{"name":"rate-me","paths":{"default":"rate-me"},"children":[{"name":"[username]","paths":{"default":":username"}}]},{"name":"u","paths":{"default":"u"},"children":[{"name":"[username]","paths":{"default":":username"}}]}]}}`), appWithTranslation(App, i18nConfig))
