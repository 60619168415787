import type {FC, ReactNode} from 'react';
import {createContext, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import {
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    getAuth,
    signInWithCredential,
    onAuthStateChanged,
    FacebookAuthProvider,
    User as FirebaseUser
} from 'firebase/auth';
import {firebaseApp, firestore} from '../lib/firebase';
import type {User} from '../types/user';
import {doc, getDoc, onSnapshot} from "firebase/firestore";
import axios from "axios";
import nookies from "nookies"
import Script from "next/script";

const auth = getAuth(firebaseApp);

interface State {
    isInitialized: boolean;
    isAuthenticated: boolean;
    isAdmin: boolean;
    user: User | null;
}

export interface AuthContextValue extends State {
    platform: 'Firebase';
    createUserWithEmailAndPassword: (
        email: string,
        password: string
    ) => Promise<any>;
    signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
    signInWithGoogle: () => Promise<any>;
    signInWithFacebook: () => Promise<any>;
    requestFacebookFriends: () => Promise<any>;
    logout: () => Promise<void>;
    getIdToken: () => Promise<any>;
    deleteAccount: (reasonMessage: string) => Promise<void>;
}

interface AuthProviderProps {
    children: ReactNode;
}

enum ActionType {
    AUTH_STATE_CHANGED = 'AUTH_STATE_CHANGED',
    USER_DATA_CHANGED = "USER_DATA_CHANGED"
}

type AuthStateChangedAction = {
    type: ActionType.AUTH_STATE_CHANGED;
    payload: {
        isAuthenticated: boolean;
        isAdmin: boolean;
        user: User | null;
    };
};

type UserDataChangedAction = {
    type: ActionType.USER_DATA_CHANGED;
    payload: {
        user: User | null;
    };
};

type Action = AuthStateChangedAction | UserDataChangedAction;

const initialState: State = {
    isAuthenticated: false,
    isAdmin: false,
    isInitialized: false,
    user: null
};

function isUserEqual(facebookAuthResponse: fb.AuthResponse, firebaseUser: FirebaseUser | null) {
    if (firebaseUser) {
        const providerData = firebaseUser.providerData;
        for (let i = 0; i < providerData.length; i++) {
            if (providerData[i].providerId === FacebookAuthProvider.PROVIDER_ID &&
                providerData[i].uid === facebookAuthResponse.userID) {
                // We don't need to re-auth the Firebase connection.
                return true;
            }
        }
    }
    return false;
}


const reducer = (state: State, action: Action): State => {
    if (action.type === 'AUTH_STATE_CHANGED') {
        const {isAuthenticated, isAdmin, user} = action.payload;

        return {
            ...state,
            isAuthenticated,
            isAdmin,
            isInitialized: true,
            user
        };
    } else if (action.type === 'USER_DATA_CHANGED') {
        const {user} = action.payload;

        return {
            ...state,
            user
        };
    }

    return state;
};

export const AuthContext = createContext<AuthContextValue>({
    ...initialState,
    platform: 'Firebase',
    createUserWithEmailAndPassword: () => Promise.resolve(),
    signInWithEmailAndPassword: () => Promise.resolve(),
    signInWithGoogle: () => Promise.resolve(),
    signInWithFacebook: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    getIdToken: () => Promise.resolve(),
    deleteAccount: (reasonMessage: string) => Promise.resolve(),
    requestFacebookFriends: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const {children} = props;
    const [state, dispatch] = useReducer(reducer, initialState);


    // const [facebook, setFacebook] = useState<FacebookStatic>()


    useEffect(() => auth.onIdTokenChanged(async (user) => {

        if (user) {
            // Here you should extract the complete user profile to make it available in your entire app.
            // The auth state only provides basic information.

            const tokenResult = await user.getIdTokenResult(false)
            nookies.set(undefined, 'token', tokenResult.token, {path: '/'});

            const userDoc = await getDoc(doc(firestore, "users", user.uid))

            const {displayName, photoUrl, username, interested, certBody, certLevel} = userDoc.data() as any

            dispatch({
                type: ActionType.AUTH_STATE_CHANGED,
                payload: {
                    isAuthenticated: true,
                    isAdmin: tokenResult.claims["admin"] === "true" || false,
                    user: {
                        uid: user.uid,
                        photoUrl: photoUrl || undefined,
                        email: user.email || 'hello@seacrush.com',
                        name: displayName || 'Unknown User',
                        username: username || "unknown",
                        plan: 'Premium',
                        certBody: certBody || "Unknown",
                        certLevel: certLevel || "Unknown",
                        interested: interested || {}

                    }
                }
            });
        } else {
            dispatch({
                type: ActionType.AUTH_STATE_CHANGED,
                payload: {
                    isAuthenticated: false,
                    isAdmin: false,
                    user: null
                }
            });
        }
    }), [dispatch]);


    useEffect(() => {

        if (state?.user?.uid) {
            return onSnapshot(doc(firestore, "users", state.user.uid), (userDoc) => {
                //console.log("onSnapshot()")
                const {displayName, photoUrl, username, email, interested, certBody, certLevel} = userDoc.data() as any
                //console.log(photoUrl)
                dispatch({
                    type: ActionType.USER_DATA_CHANGED,
                    payload: {
                        user: {
                            uid: userDoc.id,
                            photoUrl: photoUrl || undefined,
                            email: email || 'hello@seacrush.com',
                            name: displayName || 'Unknown User',
                            username: username || "unknown",
                            plan: 'Premium',
                            certBody: certBody || "Unknown",
                            certLevel: certLevel || "Unknown",
                            interested: interested || {}

                        }
                    }
                });

            })
        }
    }, [dispatch, state.isAuthenticated]);


    const _signInWithEmailAndPassword = async (email: string, password: string): Promise<void> => {
        await signInWithEmailAndPassword(auth, email, password);
    };

    const signInWithGoogle = async (): Promise<void> => {
        const provider = new GoogleAuthProvider();

        await signInWithPopup(auth, provider);
    };

    const signInWithFacebook = async (): Promise<void> => {

        console.log("signInWithFacebook()")

        // window.FB.getLoginStatus((response) => {
        //     console.log(response.status)
        // })


        // console.log("facebook: " + JSON.stringify(window.FB))

        // const provider = new FacebookAuthProvider();
        // const scopes = "public_profile,email,user_friends,user_location,user_hometown,user_gender,user_birthday".split(",")
        const scope = "public_profile,email,user_friends,user_location,user_hometown,user_gender,user_birthday"

        try {
            window.FB.login(
                (response) => {
                    console.log(
                        "FB.login() response received",
                        response.authResponse.grantedScopes
                    );


                },
                {
                    scope,
                }
            );
        } catch (e) {
            console.log(e.message)
        }


        // for (const scope of scopes) {
        //     provider.addScope(scope)
        // }
        //
        // await signInWithPopup(auth, provider);
    };

    const _createUserWithEmailAndPassword = async (email: string, password: string): Promise<void> => {
        await createUserWithEmailAndPassword(auth, email, password);
    }


    const requestFacebookFriends = async () => {
        // console.log("request facebook friends? " + facebook)
        const scope =    "user_friends";

        window.FB.login(
            (response) => {
                console.log(
                    "FB.login() response received",
                    response.authResponse.grantedScopes
                );

                if ( response.authResponse?.grantedScopes &&
                    (response.authResponse?.grantedScopes?.indexOf("user_friends") > -1)
                ) {
                    axios(`/api/friends/refresh`, {
                        method: "post",
                        withCredentials: true,
                        headers: { "content-type": "application/json" },
                        data: {
                            fbid: response.authResponse.userID,
                            token: response.authResponse.accessToken,
                        },
                    }).then(
                        (response) => {
                            console.log("request made to get more friends");
                        },
                        (error) => console.error(error.message)
                    );
                }
            },
            {
                scope: scope,
                auth_type: "rerequest",
                return_scopes: true,
            }
        );
    }

    const logout = async (): Promise<void> => {
        await signOut(auth);
        nookies.destroy(undefined, 'token', {path: '/'});
    };

    const deleteAccount = async (reasonMessage: string): Promise<void> => {

        if (auth.currentUser) {

            const token = await auth.currentUser?.getIdToken(true);

            const response = await axios.post(
                `/api/users/${auth.currentUser?.uid}/request-deletion`,
                {
                    message: reasonMessage,
                },
                {
                    withCredentials: true,
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        }

    };

    const getIdToken = async (): Promise<string> => {
        return auth.currentUser?.getIdToken() || ""
    }

    const checkLoginState = (response: fb.StatusResponse) => {
        if (response.authResponse) {
            // User is signed-in Facebook.
            const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
                unsubscribe();
                // Check if we are already signed-in Firebase with the correct user.
                if (!isUserEqual(response.authResponse, firebaseUser)) {
                    // Build Firebase credential with the Facebook auth token.
                    const credential = FacebookAuthProvider.credential(
                        response.authResponse.accessToken);

                    // Sign in with the credential from the Facebook user.
                    signInWithCredential(auth, credential)
                        .catch((error) => {
                            // Handle Errors here.
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            // The email of the user's account used.
                            const email = error.customData.email;
                            // The AuthCredential type that was used.
                            const credential = FacebookAuthProvider.credentialFromError(error);


                            // ...
                        });
                } else {
                    // User is already signed-in Firebase with the correct user.
                }
            });
        } else {
            // User is signed-out of Facebook.
            window.alert("signed out")
            signOut(auth);
        }
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                platform: 'Firebase',
                getIdToken: getIdToken,
                createUserWithEmailAndPassword: _createUserWithEmailAndPassword,
                signInWithEmailAndPassword: _signInWithEmailAndPassword,
                signInWithGoogle,
                signInWithFacebook,
                logout,
                deleteAccount,
                requestFacebookFriends
            }}
        >
            <Script id="facebook-sdk"
                    src="//connect.facebook.net/en_US/sdk.js"
                    strategy="lazyOnload"
                    onLoad={() => {
                        // console.log("onLoad facebook sdk")
                        window.FB.Event.subscribe('auth.authResponseChange', checkLoginState);
                        window.FB.init({
                            appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
                            status: true,
                            cookie: true,
                            xfbml: true,
                            version: "v3.1",
                        });

                    }}
            />
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
