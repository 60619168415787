

// There's another file like this in the seacrush-web root dir that should be updated in tandem
export const i18nConfig = {
    i18n: {
        serializeConfig: false, // because of the custom use i18next plugin
        locales: ['default', 'en', 'fr'],
        defaultLocale: 'default',
        defaultNS: "frontend",
        ns: ["frontend"],
        localeDetection: true,
        localePath: (locale: string, namespace: string, env: string, missing: boolean) => `public/locales/${namespace}-${locale}.json`,
        // localeStructure: "{{ns}}-{{lng}}",
        // localeExtension: "json",
        reloadOnPrerender: false // TODO: set to true for development only
    }
}
