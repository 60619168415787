import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { tripResultsApi } from '../frontend-api/trip-api';
import {TripInterface} from "../types/trip";

interface TripsState {
    trips: {
        tripsArray: TripInterface[];
        byId: Record<string, TripInterface>;
        allIds: string[];
    };
    isLoaded: boolean;
    criteria: {
        selectedContinent: string;
        selectedPeriod: string;
    }
}

const initialState: TripsState = {
    trips: {
        tripsArray: [],
        byId: {},
        allIds: []
    },
    isLoaded: false,
    criteria: {
        selectedContinent: "",
        selectedPeriod: ""
    }

};

const slice = createSlice({
    name: 'trips',
    initialState,
    reducers: {
        getTrips: function (state: TripsState, action: PayloadAction<TripInterface[]>): void {
            const trips = action.payload;

            state.trips.byId = trips.reduce((
                accumulator,
                current
            ) => {
                // @ts-ignore
                accumulator[current.tripId] = current
                return accumulator;
            }, {});

            state.trips.tripsArray = trips

            state.trips.allIds = Object.keys(state.trips.byId);
            state.isLoaded = true
        },
        showLoading(state: TripsState): void {
            state.isLoaded = false;
        },
        setCriteriaContinent(state: TripsState, action: PayloadAction<string>): void {
            state.criteria.selectedContinent = action.payload
        },
        setCriteriaPeriod(state: TripsState, action: PayloadAction<string>): void {
            state.criteria.selectedPeriod = action.payload
        },
    }
});


export const { reducer } = slice;

export const getTrips = ({language} : {language: string}): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.showLoading());

    const data = await tripResultsApi.getTrips(language);

    dispatch(slice.actions.getTrips(data));
};

export const setCriteriaContinent = (continentId: string): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.setCriteriaContinent(continentId))
};

export const setCriteriaPeriod = (period: string): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.setCriteriaPeriod(period))
};


