import React, {createRef, useRef, useState} from "react";
import {createStyles, makeStyles, useTheme} from "@mui/styles";
import classNames from "clsx";
import Modal from "@mui/material/Modal";
import {useTranslation} from "react-i18next";

import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import SwipeableViews from "react-swipeable-views";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import {BasicForm} from "./basic-form";
import {ProfileForm} from "./profile-form";
import {AlmostThere} from "./almost-there";
import {LoadingButton} from "@mui/lab";
import {useDispatch, useSelector} from "../../store";
import {setShowOnboarding, setActiveStep} from "../../slices/onboarding-slice";
import useWidth from "../../hooks/use-width";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      zIndex: 2000000001,
    },
    heart: {
      width: 60,
      display: "block",
      margin: "0 auto",
    },
    title: {
      textAlign: "center",
    },
    closeButton: {
      position: "absolute",
      right: 8,
      top: 8,
      color: "gray",
      cursor: "pointer",
    },
    fullscreen: {
      margin: 0,
      width: "100%",
      maxWidth: "100%",
      height: "100%",
      maxHeight: "none",
      borderRadius: 0,
    },
    modal: {
      position: "absolute",
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,

      backgroundColor: "#fff",
      boxShadow: "0 5px 15px rgba(0, 0, 0, .5)",

      minWidth: 270,
      maxWidth: 900,
      [theme.breakpoints.down("xs")]: {
        maxWidth: 700,
      },
    },
    desktopRight: {
      width: 500,
      backgroundColor: "#0c5ea7",
      backgroundImage: 'url("/images/onboarding/onboarding1.webp")',
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      transition: "background-image 1s",
    },
    page2: {
      backgroundImage: 'url("/images/onboarding/onboarding2.webp")',
    },
    page3: {
      backgroundImage: 'url("/images/onboarding/onboarding3.webp")',
    },
    signUpContent: {
      border: "1px solid #e5e5e5",
      minHeight: 430,
      [theme.breakpoints.up("sm")]: {
        maxWidth: 370,
        width: 370,
      },
    },
    logo: {
      [theme.breakpoints.down("xs")]: {
        width: 240,
      },
      width: 270,
      marginBottom: 16,
    },
    list: {
      marginLeft: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(4),
      },
      listStyle: "none",
      position: "relative",
      marginTop: 4,
      marginBottom: 24,
      fontSize: "1rem",
      textAlign: "left",
    },
    fullscreenStepper: {
      paddingBottom: 16,
    },
  })
);


export interface OnboardingChildProps {
  advanceParentToNextStep: () => void,
  showParentProgress: (show: boolean) => void
}

export interface OnboardingChildRefInterface {
  submitStep: () => void
}


export const Onboarding = () => {

  const theme = useTheme()
  const classes = useStyles(theme);

  const { t } = useTranslation();

  const {showOnboarding, activeStep} = useSelector((state) => state.onboarding);

  const [submittingStep, setSubmittingStep] = useState(false);

  const [formData, setFormData] = useState({});

  const dispatch = useDispatch();

  const fullScreen = useWidth() === "xs"

  const maxSteps = 3;

  const stepComponentsRef = useRef([...Array(maxSteps)].map(() => createRef<OnboardingChildRefInterface>()));

  const handleChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleBackButton = (event: any) => {

    dispatch(setShowOnboarding(false));
  };

  const handleClose = (e: any, reason: string) => {
    dispatch(setShowOnboarding(false));
  };

  const handleNext = () => {
    if (stepComponentsRef.current) {
      stepComponentsRef.current[activeStep]?.current?.submitStep();
    }
  };

  const handleStepChange = (newStep: number) => {
    dispatch(setActiveStep(newStep))
  };

  const handleBack = () => {
    dispatch(setActiveStep(activeStep - 1))
  };

  // called by children
  const showProgress = (shouldShow: boolean) => {
    setSubmittingStep(shouldShow);
  };

  // called by children
  const advanceToNextStep = () => {
    console.log(`advanceToNext()`);

    setSubmittingStep(false);

    if (activeStep === maxSteps - 1) {
      dispatch(setShowOnboarding(false));
      // dispatch(finishedOnboarding());
    } else {
      dispatch(setActiveStep(activeStep + 1));
    }
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      open={showOnboarding}
      onClose={handleClose}
      disableAutoFocus={true}
      className={classes.root}
    >
      <div
        className={classNames(classes.modal, {
          [classes.fullscreen]: fullScreen,
        })}
      >
        <Grid
          container
          direction="row"
          wrap="nowrap"
          style={{ height: "100%" }}
        >
          <Grid item className={classes.signUpContent} xs={12}>
            <div>
              <SwipeableViews
                disabled={true}
                enableMouseEvents={false}
                index={activeStep}
                onChangeIndex={handleStepChange}
              >
                <div>
                  <BasicForm
                    ref={stepComponentsRef.current[0]}
                    advanceParentToNextStep={advanceToNextStep}
                    showParentProgress={showProgress}
                  />
                </div>
                <div style={{overflow: "hidden"}}>
                  <ProfileForm
                    ref={stepComponentsRef.current[1]}
                    advanceParentToNextStep={advanceToNextStep}
                    showParentProgress={showProgress}
                  />
                </div>
                <div>
                  <AlmostThere
                    ref={stepComponentsRef.current[2]}
                    advanceParentToNextStep={advanceToNextStep}
                    showParentProgress={showProgress}
                  />
                </div>
              </SwipeableViews>

              <MobileStepper
                steps={maxSteps}
                position={fullScreen ? "bottom" : "static"}
                className={classNames({
                  [classes.fullscreenStepper]: fullScreen,
                })}
                activeStep={activeStep}
                nextButton={
                  <div>
                    {activeStep === maxSteps - 1 && (
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={submittingStep}
                      >
                        {t("done")}
                      </Button>
                    )}
                    {activeStep < maxSteps - 1 && (
                      <LoadingButton
                        size="small"
                        onClick={handleNext}
                        loading={submittingStep}
                        endIcon={<KeyboardArrowRight />}
                      >
                        {t("next")}
                      </LoadingButton>
                    )}
                  </div>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    style={{
                      visibility: activeStep === 0 ? "hidden" : "visible",
                    }}
                  >
                    <KeyboardArrowLeft />
                    {t("back")}
                  </Button>
                }
              />
            </div>
          </Grid>
          <Hidden mdDown>
            <Grid
              item
              className={classNames(classes.desktopRight, {
                [classes.page2]: activeStep === 1,
                [classes.page3]: activeStep === 2,
              })}
            ></Grid>
          </Hidden>
        </Grid>
      </div>
    </Modal>
  );
}
